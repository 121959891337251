import React, {useEffect} from "react";
import "./valentine.css";

const Valentine = () => {

    return (
        <div style={{ width: "100vw", height: "100vh", border: "none", background: "white" }}>
            <iframe
            src="https://will-you-b-my-valentine.vercel.app/"
            width="100%"
            height="100%"
            style={{ border: "none" }}
            />
        </div>
    )
}
export default Valentine;